<template>
	<div class="projects">
		<div class="wrap">
			<h2>Open Source</h2>
			<p>
				Most of my personal projects are Open Source and avaiable on <a
					href="https://github.com/Schascha"
					title="More on GitHub"
					rel="noopener noreferrer"
					target="_blank"
				>GitHub</a>.
			</p>
		</div>

		<ul>
			<li
				v-for="{code, image, link, name, text, theme, url, year} in items"
				:key="name"
			>
				<div class="card">
					<a
						:href="link"
						rel="noopener noreferrer"
						target="_blank"
						:title="`More about ${name}`"
					>
						<div
							class="card-image"
							:style="{backgroundColor: theme}"
						>
							<div class="card-external-container">
								<IconExternalLink />
								<span class="card-external-link">
									{{ url }}
								</span>
							</div>
							<img
								v-if="image[0] && image[1]"
								:alt="`Preview ${name}`"
								:src="image[0]"
								:srcset="`${image[0]} 1x, ${image[1]} 2x`"
								width="300"
								height="225"
								loading="lazy"
							>
						</div>

						<div class="card-text">
							<h3>{{ name }}</h3>

							<p v-if="text">
								{{ text }}
							</p>

							<span :class="['card-code', code.toLowerCase()]">
								{{ code }}
							</span>

							<span class="card-year">
								{{ year }}
							</span>
						</div>
					</a>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import projects from '@/data/projects.json';
import IconExternalLink from '@/assets/icons/external-link.svg?inline';

export default {
	components: {
		IconExternalLink
	},
	data() {
		return {
			items: projects.map(el => {
				el.image = [this.imageSrc(el.slug), this.imageSrc(`${el.slug}@2x`)];
				el.url = el.link.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').replace(/\/$/, '');
				return el;
			})
		};
	},
	methods: {
		imageSrc(slug) {
			try {
				const image = require(`@/assets/projects/${slug}.jpg`);
				return image;
			} catch (e) {
				// I want application to not crush, but don't care about the message
			}
		}
	}
};
</script>

<style lang="scss" scoped>
	h3 {
		margin: 0;
	}

	ul {
		display: flex;
		align-items: stretch;
		overflow-y: hidden;
		position: relative;
		margin: calc(var(--space-20) * -1) calc(var(--space-5) * -1) calc(var(--space-10) * -1);
		padding: var(--space-10) 0;
		list-style: none;
		text-align: left;
		white-space: nowrap;
		scroll-behavior: smooth;
		scroll-snap-type: x mandatory;

		@include mq($break) {
			scroll-padding: 0 calc(50vw - (var(--max-width) / 2) - var(--space-5));
		}
	}

	li {
		flex: 0 0 auto;
		padding: 0.5rem var(--space-5);
		scroll-snap-align: start;

		@include mq($break) {
			&:first-child {
				padding-left: calc(50vw - (var(--max-width) / 2));
			}

			&:last-child {
				padding-right: calc(50vw - (var(--max-width) / 2));
			}
		}
	}

	.card {
		overflow: hidden;
		width: 300px;
		height: 100%;
		border-radius: 1rem;
		box-shadow: 0 0.6rem 0.6rem -0.3rem rgb(0 0 0 / 10%);
		white-space: normal;

		a {
			display: flex;
			flex-direction: column;
			height: inherit;
			color: inherit;
			text-decoration: none;

			&:hover,
			&:focus {
				img {
					opacity: 0.8;
				}

				.card-external-link {
					margin-left: rem(4);
					max-width: 200px;
				}
			}
		}

		p {
			margin: 0;
			font-size: 1rem;
		}

		&-image {
			overflow: hidden;
			position: relative;

			&::before {
				content: '';
				display: block;
				padding-top: 75%;
			}

			img {
				transition: opacity var(--transition);
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		&-text {
			flex: 1;
			position: relative;
			padding: 1rem 1rem 3rem;
			background-color: var(--color-content);
		}

		&-code {
			display: inline-flex;
			align-items: center;
			gap: 0.5rem;
			position: absolute;
			bottom: 1rem;
			left: 1rem;
			font-size: rem(12);

			&::before {
				content: '';
				width: rem(12);
				height: rem(12);
				background-color: var(--color-code);
				border-radius: 50%;
			}
		}

		&-year {
			position: absolute;
			right: 1rem;
			bottom: 1rem;
			font-size: rem(12);
		}

		&-external-container {
			transition: max-width var(--transition);
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			right: 1rem;
			bottom: rem(10);
			z-index: 1;
			padding: rem(4);
			min-width: rem(24);
			background-color: var(--color-content);
			border-radius: 3px;
			font-size: rem(12);
			font-weight: 600;
			line-height: normal;

			svg {
				--icon-size: #{rem(14)};
			}
		}

		&-external-link {
			transition:
				max-width var(--transition),
				margin var(--transition);
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			margin: 0;
			max-width: 0;
			white-space: nowrap;
		}

		@each $name, $color in $color-code {
			.#{$name} {
				--color-code: #{$color};
			}
		}
	}
</style>
